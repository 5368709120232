.App {
    margin: 0;
    width: inherit;
}
body {
    margin: 0;
    width: inherit;
}
#Home {
    margin: 0;
    width: inherit;
}
.row>* {
    /* flex-shrink: unset;
    width: unset;
    max-width: unset;
    padding-right: unset;
    padding-left: unset;
    margin-top: unset; */
    all: unset;
}

/* .row {
    all:unset;
 }
 */