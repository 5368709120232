.Navsec {
    background: linear-gradient(to right, white , #00b3ff4d);
    width: -webkit-fill-available;
    padding: 2% 5%;
}
nav {
    background: linear-gradient(to right, white , #00b3ff4d);
}
.Navsec .navbar {
    width: 100%;
}
.Navsec .navbar-brand {
    width: 33.33%;
}
.Navsec .navbar-nav {
   width: 50%;
}
#midcov {
    justify-content: center;
}
#midnav {
    justify-content: center;
    color: #14314E;
    font-size: 18px;
}
#endnav {
    justify-content: end;
}
#endnav button {
    background-color: #14314E;
    color: white;
    border-radius: 10px;
    padding: 4% 5%;
} 
.navbar-toggler {
    border: #20486E 2px solid;
    color: #20486E;
}
.navbar-toggler:active {
    border: #20486E 2px solid;
    color: #20486E;
}
.navbar-toggler:hover {
    border: 4px #20486E solid;
}
.navbar-collapse {
    text-align: -webkit-center;
}
.show li {
    padding: 2%;
}
.show #endnav {
    width: 100%;
}
.show #endnav button {
    padding: 4% 5%;
}
@media screen and (min-width: 900px) {
    #endnav button {
        padding: 2% 5%;
    }
}