#Home {
    background-color: #FAF9F6;
}
#header {
    display: flex;
    background-image: linear-gradient(to right, white , #00b3ff4d);
    padding: 1.5% 5%;
    width: -webkit-fill-available;
}
#header .row { 
    display: flex;
    width: 100%;
}
#header .row .col-6 {
    width: 50%;
    text-align: start;
}
#Home #header #admtxt {
    background-color: #00A0F1;
    color: white;
    width: 100px; 
    padding: 12px 17px; 
    border-radius: 25px;
    font-weight: 420;
    font-size: 25px;
}

#headdt {
    margin-top: 7%;
}
#Home h1 {
    font-size: 50px;
    margin-top: 5%;
}
#Home #header p {
    font-size: 22px;
    font-weight: 400;
}
#fetsec {
    text-align: center;
    font-family: Plus Jakarta Sans;
    width: -webkit-fill-available;
    display: grid;
}
#fetsec h2{
    color: #14314E;
    font-size: 35px;
    font-weight: 600;
    padding: 0.5% 0px;
}
#fetsec p{
    font-size: 20px;
    line-height: 30px;
    font-weight: 450;
    padding: 0.5% 0px;
}
#dloadrow {
    display: flex;
}
#colinit {
    background-color: white;
    margin-right: 2%; 
    place-items: center; 
    padding: 0px 5%; 
    width: 50%;
}
#colinit2 {
    background-color: white;
    margin-left: 2%;
    place-items: center;
    padding: 0px 5%; 
    width: 50%;
}
#dloadrow img {
    padding-right: 20px; 
    height: 50px;
}
#featrow {
    margin-top: 10%;
    display: flex;
    font-family: Plus Jakarta Sans;
}
#fetsec {
    padding: 30px 0px;
}
.row #wrapper {
    bottom: 15%;
    display: grid;
    position: relative;
    place-items: center;
}
.row #wrapper img {
    padding-bottom: 40px;
}
#head {
    font-weight: 800;
    font-size: 30px;
    color: #14314E; 
    padding-bottom: 15px;
}
#bodtxtx {
    font-weight: 400; 
    font-size: 19px; 
    color: #0F172A;
}
#body1 {
    text-align: center;
    padding: 25px 10%;
    background-color: #00b3ff12;
    justify-content: center;
}
#body1 h2{
    color: #14314E;
    font-family: Plus Jakarta Sans;
    font-size: 40px;
    font-weight: 600;
}
#body2 {
    background-color: #00A0F1;
}
#body3 {
    justify-content: center;
    display: grid;
    place-content: center;
    width: -webkit-fill-available;
    padding: 5% 0px;
}
#body3 h2 {
    font-family: Plus Jakarta Sans;
    color: #14314E;
    font-size: 35px;
    font-weight: 800;
    width: -webkit-fill-available;
    text-align: center;
    padding-bottom: 2%;
}
#body3 #stl {
    display: flex;
    justify-content: center;
    width: -webkit-fill-available;
    place-items: center;
}
#body3 #stl span {
    align-content: center;
    text-align: center;
}
#body3 #stl img {
    place-items: center;
    text-align: center;
    padding: 0px 30px;
}
#body4 {
    display: flex;
    padding: 25px 10%;
    font-family: Plus Jakarta Sans;
}
#body4 .col {
    width: 50%;
}
#body4 span {
    display: block;
    width: 85%;
}
#body4 h2 {
    color: #14314E;
    font-weight: 750;
    font-size: 35px;
    text-align: start;
}
#body4 p {
    color: #0F172A;
    font-weight: 330;
    font-size: 23px;
    text-align: start;
}
#body4 .card {
     display: grid;
     width: 60%;
     border-radius: 15px;
     border-left:#01AAFF solid 10px;
     background-color: white;
     padding: 20px 17px;
     text-align: left;
     position: relative;
     bottom: 20%;
     left: 30%;
     box-shadow: 2px 2px #0f172a4b;
}
#body4 .card p {
    color: #5F5F7E;
    font-family: "Nunito Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 35px;
    margin-left: 20px;
}
#spt {
    font-size: 20px;
}
#promname {
    color: #5F5F7E;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 650;
    font-size: 22px;
}
@media screen and (min-width: 1025px)and (max-width: 1500px) {
    #headdt {
        margin-top: 2%;
    }
    #head {
        font-size: 25px;
    }
    #bodtxtx {
        font-weight: 300; 
        font-size: 15px; 
    }
    #Home h1 {
        font-size: 45px;
    }
    #break1 {
        display: none;
    }
    #dloadrow img {
        padding-right: 10px; 
    }
    .row #wrapper {
        bottom: 8%;
    }
    #body3 #stl img {
        padding: 0px 25px;
        width: 140px;
    }
    #body4 {
        padding: 25px 5%;
        padding-top: 10%;
    }
    #body4 h2 {
        font-size: 25px;
    }
    #body4 img {
        width: 80%;
    }
    #body4 .card {
        bottom: 25%;
        height: auto;        
        padding: 5% 5%;
    }
    #spt {
        font-size: 13px;
    }
    #body4 .card img {
        width: auto;
        height: auto;
    }
    #body4 .card span {
        font-size: 17px;
    }
    #body4 .card p {
        font-size: 20px;
        line-height: 30px;
    }
}
@media screen and (min-width: 768px)and (max-width: 1024px) {
    #headdt {
        margin-top: 2%;
    }
    #Home h1 {
        font-size: 35px;
    }
    #Home #header p {
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
    }
    #break1 {
        display: none;
    }
    #dloadrow img {
        padding-right: 10px; 
    }
    .row #wrapper {
        bottom: 8%;
    }
    #body3 #stl img {
        padding: 0px 10px;
        width: 80px;
    }
    #body4 {
        padding: 25px 5%;
        padding-top: 10%;
    }
    #body4 h2 {
        font-size: 25px;
    }
    #body4 img {
        width: 80%;
    }
    #body4 .card {
        bottom: 25%;
        height: auto;
        padding: 2% 2%;
    }
    #spt {
        font-size: 13px;
    }
    #body4 .card img {
        width: auto;
        height: auto;
    }    
    #body4 .card span {
        font-size: 15px;
    }
    #body4 .card p {
        font-size: 16px;
        line-height: 25px;
    }
    #head {
        font-size: 20px;
    }
    #bodtxtx {
        font-weight: 300; 
        font-size: 12px; 
    }
}

@media screen and (max-width: 770px) {
    #header {
        display: grid;
    }
    #header .row {
        display: grid;
        justify-content: center;
    }
    #header .row .col-6 {
        width: 100%;
    }
    #Home #header #admtxt { 
        font-size: 18px;
    }
    #Home #header h1 {
        font-size: 30px;
    }
    #Home #header p {
        font-size: 18px;
    }
    #dloadrow img {
        padding-right: 15px;
        height: unset;
    }
    #break1 {
        display: none;
    }
    #hdimg {
        padding-top: 8%;
    }
    #fetsec {
        padding: 30px 12px;
    }
    #fetsec h2 {
        font-size: 30px;
        line-height: 35px;
    }
    #body1 {
        display: grid;
        width: -webkit-fill-available;
    }
    #body1 #head {
        line-height: 35px;
    }
    #featrow {
        display: grid;
    }
    #body1 h2 {
        margin-bottom: 5%;
    }
    #featrow .col {
        width: -webkit-fill-available;
        margin-bottom: 20%;
    }
    #colinit, #colinit2 {
        width: -webkit-fill-available;
    }
    .row #wrapper {
        bottom: 10%;
        justify-content: center;
    }
    #body3 #stl {
        display: inline;
        text-align: center;
    }
    #body3 #stl span  {
        width: 50%;
        display: inline-table;
    }
    #body3 #stl img {
        padding: 5px 0px;
        width: inherit;
    }
    #body4 {
        display: grid;
        padding-bottom: 0px;
    }
    #body4 span {
        width: 100%;
    }
    #body4 .col {
        width: -webkit-fill-available;
    }
    #body4 h2 {
        text-align: center;
        font-size: 30px;
    }
    #body4 p {
        text-align: center;
    }
    #body4 img {
        width: 100%;
    }
    #body4 .card {
        width: 100%;
        left: 10%;
    }
    #body4 .card p {
        margin-left: 10px;
        text-align: left;
        font-size: 18px;
        line-height: 27px;
    }
    #promname {
        font-size: 20px;
        font-weight: 800;
    }
    .Team .swiper {
        margin: 0px;
      }
}