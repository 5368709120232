.Team {
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #00A0F1;
}
.Team #teamtxt {
    font-size: 45px; 
    font-weight: 500; 
    padding: 2% 0px;
    width: -webkit-fill-available;
}
.Team .swiper{
  display: none;
}

.Team .slider-container{
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: unset;
  align-items: center;
  justify-content: center;
}
.slider-images{
  display: flex;
  align-items: center;
  gap: 1% /* 21px */;
}
.Team .slider-images img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.Team .slider-img{
  width: 10.5%;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  transition: 0.7s ease;
}
.slider-images .slider-img:first-child, .slider-images .slider-img:last-child{
  height: 480px;
}
.slider-images .slider-img:nth-child(2), .slider-images .slider-img:nth-child(6){
  height: 560px;
}
.slider-images .slider-img:nth-child(3), .slider-images .slider-img:nth-child(4), .slider-images .slider-img:nth-child(5){
  height: 665px;
}
.slider-container h1{
  font-family: "Jost", sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  top: 50%;
  left: -10%;
  transform: rotate(270deg);
  transition: 0.7s ease;
}
.details{
  position: absolute;
  bottom: 43px;
  left: 43px;
}
.details h2{
  font-family: "Jost", sans-serif;
  font-size: 26px;
  font-weight: 700;
  text-align: left;
  line-height: 44px;
  text-align: left;
  color: #fff;
  text-transform: uppercase;
  transition: 0.7s ease;
  display: none;
}
.details p{
  font-family: "Jost", sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  line-height: 33px;
  text-align: left;
  color: #fff;
  text-transform: uppercase;
  transition: 0.7s ease;
  display: none;
}
.Team .slider-img.active{
  width: 31% !important;
  height: auto !important;
}
.slider-img.active h1{
   display: none;
}
.slider-img.active .details p, .slider-img.active .details h2{
  display: block;
}



.swiper_container { 
    height: 52rem;
    padding: 2rem 0;
    position: relative;
  }
  .Team .swiper {
    justify-content: center;
    width: 80%;
  }
  .Team .swiper .swiper-wrapper, .Team .swiper-3d .swiper-wrapper {
    place-content: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    display: flex;
    width: 55%;
    height: 500px;
  }

  .Team .swiper-slide {
    width: 120%;
    height: 42rem;
    position: relative;
  }
  .swiper-pagination {
    display: contents;
  }
  
  @media (max-width: 500px) {
    .swiper_container {
      height: 47rem;
    }
  }
  .swiper-slide img {
    height: 80%;
    border-radius: 2rem;
    object-fit: cover;
  }
  
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    display: none;
  }
  
  .slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
  }
  
  @media (max-width: 990px) {
    .slider-controler .swiper-button-next {
      left: 70% !important;
      transform: translateX(-70%) !important;
    }
  }
  
  @media (max-width: 450px) {
    .slider-controler .swiper-button-next {
      left: 80% !important;
      transform: translateX(-80%) !important;
    }
  }
  
  @media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
      left: 30% !important;
      transform: translateX(-30%) !important;
    }
  }
  
  @media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
      left: 20% !important;
      transform: translateX(-20%) !important;
    }
  }
  
  .Team .slider-controler .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }
  
  .slider-controler .slider-arrow ion-icon {
    font-size: 2rem;
    color: #222224;
  }
  
  .slider-controler .slider-arrow::after {
    content: '';
  }
  
  .Team .swiper-pagination {
    position: relative;
    width: 15rem !important;
    bottom: 1rem;
  }
  
  .swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }
  
  .swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary);
  }

@media screen and (max-width: 770px) {
    .Team #teamtxt {
        font-size: 35px; 
        font-weight: 400;
        padding: 20px 0px;
    }
    .Team .slider-container {
      display: none;
    }
    
    .Team .swiper {
      display: flex;
      height: max-content;
      display: flow-root;
      width: -webkit-fill-available;
    }
    .Team .swiper-slide img {
      height: 440px;
      width: auto;  
    }
    .Team .swiper-3d .swiper-wrapper {
      height: -webkit-fill-available;
    }
}