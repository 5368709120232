.FAQs {
    background-color: #FAF9F6;
}
#searchbx, #faqmain {
    background-image: linear-gradient(to right, white , #00b3ff4d);
}
#faqhead {
    display: grid;
    background-image: linear-gradient(to right, white , #00b3ff4d);
}
#faqhead h2 {
    color: #14314E;
    font-family: Plus Jakarta Sans;
    font-weight: 800;
    font-size: 45px;
    text-align: center;
    margin: 0;
}
#faqhead p {
    text-align: center;
    font-family: Plus Jakarta Sans;
    color: #0F172A;
    font-weight: 450;
    font-size: 20px;
    padding-bottom: 1%;
}
#searchbx {
    justify-content: center;
    text-align: center;
}
#searchbx input {
    justify-content: center;
    height: 60px;
    width: 70%;
    background-color: white;
    border: 0px;
    box-shadow: 0 0 0 0.10rem #A4A5A0 ;
    border-radius: 10px;
    color: #20486E;
    font-size: 30px;
    font-weight: 400;
    padding: 0px 20px;
/*     background-image: url('https://www.w3schools.com/css/searchicon.png');
    background-position: 1px 20px; 
    background-repeat: no-repeat; */
}
#searchbx input:active, #searchbx input:focus {
    border: 0px;
}

#searchbx input::placeholder {
    color: #20486E;
}
#faqmain {
    justify-content: center;
    display: flex;
    background-color: inherit;
}
#faqmain #wrap {
    width: 80%;
    margin: 5% 0px;
    display: flex;
}
#faqmain .col-3, #faqmain .col-9 {
    background-color: white;
    display: grid;
}
#faqmain .col-3 {
    width: 30%;
    border: 1px solid #A4A5A0;
    border-radius: 10px;
    height: max-content;
}
#faqmain .col-3 h4 {
    font-family: Plus Jakarta Sans;
    align-content: center;
    font-weight: 800;
    font-size: 30px;
    color: #14314E;
    padding: 10px 10px;
    margin: 0px;
    text-align: center;
}
#faqmain .col-3 span {
    text-align: center;
    align-content: center;
    font-weight: 500;
    font-family: Montserrat;
    font-size: 20px;
    padding: 10px 10px;
    border-top: 1px solid #A4A5A0;
}
#faqmain .col-9 {
    width: 68%;
/*     border: 1px solid #A4A5A0;
    border-radius: 10px; */
    background-color: inherit;
}
.accordion-header button {
    font-weight: 700;
    font-size: 20px;
    border-radius: 10px;
}
.collapsed {
    color: #14314E;
}
.accordion {
    background-color: inherit;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem transparent;
    --bs-accordion-active-bg: transparent;
}
.accordion-body {
    font-size: 18px;
    font-weight: 500;
    background-color: inherit;
}
.accordion-item {
/*     border: 20px solid #00b3ff4d; */
    margin: 2%;
    margin-top: 0px;
    border-radius: 10px;
    padding: 0.5% 0px;
}
.show {
/*     background-color: inherit;
    border: none;
    border-radius: 10px; */


}
/* .accordion {
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
} */

@media screen and (max-width: 770px) {
    #faqmain {
        display: grid;
        justify-items: center;
    }
    #faqmain #wrap {
        display: unset;
    }
    #wrap .col-3 {
        width: 100%;
        margin-bottom: 5%;
    }
    #wrap .col-9 {
        width: 100%;
    }
}