#body7 {
    display: flex;
    place-items: center;
    justify-content: center;
    padding: 6% 3%;
    text-align: center;
    width: -webkit-fill-available;
    background-color: #eef5f8;
}
#body7 p {
    color: #14314E;
    font-size: 45px;
    font-weight: 600;
    font-family: inter;
}
#body7 span {
    display: inline-flex;
    justify-content: center;
}
#body7 img {
    padding: 10px 60px;
}
/* #body7 .row>* {
    all: unset;
} */
@media screen and (max-width: 768px) {
    #body7 {
        display: grid;
    }
    #body7 p {
        font-size: 35px;
    }
    #body7 span {
        display: grid;
        place-items: center;
    }
}
@media screen and (min-width: 768px)and (max-width: 1500px) {
    #body7 img {
        padding: 0px 30px;
        width: 30%;
    }
}
@media screen and (min-width: 768px)and (max-width: 900px) {
    #body7 p {
        font-size: 25px;
    }
}
@media screen and (min-width: 901px)and (max-width: 1300px) {
    #body7 p {
        font-size: 28px;
    }
}