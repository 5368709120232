.Terms {
    background: linear-gradient(to right, white , #00b3ff4d);
}
.Terms #prihead {
    display: grid;
    padding: 0px 20%;
    margin: 0px;
}
#prihead h2 {
    color: #14314E;
    font-family: Plus Jakarta Sans;
    font-weight: 800;
    font-size: 45px;
    text-align: center;
    margin: 0px;
}
.Terms #termbd {
    background-color: white;
    margin: 5% 10%;
    margin-top: 2%;
    padding: 3% 3%;
    font-family: inter;
    color: #1E1E1E;
    font-size: 20px;
    font-weight: 500;
    border-radius: 10px;
}
#termbd h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 4%;
}
#termlst {
    text-align: center;
    justify-content: center;
    display: grid;
}

@media screen and (max-width: 770px) {
    .Terms #termbd {
        margin: 1% 1%;
        padding: 1% 1%;
    }
}