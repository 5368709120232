#body6 {
    background-color: #00A0F1;
    padding: 5% 7%;
    width: -webkit-fill-available;
}
#inner {
    background-color: #FAFAFA;
    padding:6% 7%;
    border-radius: 10px;
}
#inner h3 {
    font-weight: 600;
    font-size: 48px;
    text-align: left;
    font-family: inter;
    margin: 0px;
    color: #14314E;
}
#inner span {
    display: flex;
}
#inner span p {
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-weight: 300;
    width: 65%;
    text-align: left;
}
#joinwrap {
    align-items: baseline;
}
#inner img {
    padding-right: 15px;
    height: 70px;
    width: 200px;
    margin-top: 0px;
}
@media screen and (min-width: 1200px)and (max-width: 1500px) {
    #inner h3 {
        font-size: 40px;
    }
    #inner img {
        padding-right: 15px;
        height: 60px;
        width: 170px;
    }
}
@media screen and (min-width: 768px)and (max-width: 1000px) {
    #inner img {
        height: 35px;
        width: 80px;
    }
}
@media screen and (min-width: 768px)and (max-width: 1200px) {
    #inner h3 {
        font-weight: 600;
        font-size: 30px;
    }
    #inner img {
        padding-right: 10px;
        height: 50px;
        width: 120px;
        margin-top: 30px;
    }
    #inner p {
        font-size: 15px;
    }
}

@media screen and (max-width: 770px) {
    #inner {
        display: grid;
        justify-content: center;
    }
    #inner h3 {
        font-size: 22px;
        text-align: center;
    }
    #inner span p {
        width: -webkit-fill-available;
        text-align: center;
    }
    #inner p {
        width: -webkit-fill-available;
        text-align: center;
    }
    #inner span {
        display: grid;
        justify-content: center;
    } 
    #inner div {
        display: flex;
        justify-content: center;
    } 
    #joinwrap {
        display: grid;
    }
    #imgwrap {
        display: flex;
        justify-content: center;
    }
    #inner img {
        padding: 0px 2%;
        width: 50%;
        height: 50%;
    }
}