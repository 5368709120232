.Calculator {
    width: initial;
    background-color: #FAF9F6;
}
#calchead {
    display: grid;
    padding: 0px 20%;
    background: linear-gradient(to right, white , #00b3ff4d);
    margin: 0px;
}
#calchead h2 {
    color: #14314E;
    font-family: Plus Jakarta Sans;
    font-weight: 800;
    font-size: 45px;
    text-align: center;
    margin: 0px;
}
#calchead p {
    text-align: center;
    font-family: Plus Jakarta Sans;
    color: #0F172A;
    font-weight: 450;
    font-size: 20px;
}
#calcwrap {
    padding: 4%;
    margin: 0px;
    background: linear-gradient(to right, white , #00b3ff4d);
}
#calc {
    margin: 10px 10%;
    background-color: white;
    text-align: -webkit-center;
    justify-content: center;
    height: max-content;
    margin: 0px 18%;
    padding: 4% 2%;
    border-radius: 20px;
}
#rwstr .col-6 {
    display: grid; 
    width: 50%;
    align-content: center;
    border-radius: 10px; 
}

#calc #toggle {
    display: flex;
    background-color: #6d72803c;
    border-radius: 30px;
    padding: 5px;
    width:  max-content;
}
#calc #toggle span {
    color: #14314E;
    padding: 15px;
    border-radius: 30px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}
#calc #inputwrap {
    padding: 0px 20px;
    margin-bottom: 20px;
}
#calc #inputwrap input, #calc #inputwrap select {
    color: #20486E;
    font-size: 30px;
    font-weight: 400;
    padding: 0px 20px;
}
#calc label {
    color: #20102B;
    text-align: left;
    font-family: inter;
    font-weight: 500;
    font-size: 20px;
}
#calc input, #calc select {
    height: 50px;
    width: -webkit-fill-available;
    border-radius: 10px;
    border: 2px solid #E2E2E2;
    background-color: #F2F7F7;
    margin-top: 8px;
}
#calc input::placeholder {
    color: #9B959F;
}
#rwstr form {
    display: flex;
}
@media screen and (min-width: 801px)and (max-width: 1600px) { 
    #calchead h2 {
        font-size: 28px;
    }
}
@media screen and (min-width: 850px)and (max-width: 1500px) {
    #calcwrap {
        padding: 4% 2%;
        margin: 0px;
    }
    #calc {
        margin: 0px;
    }
    #rwstr {
        width: 100%;
        place-content: center;
    }
    #rwstr .col-6 {
        width: 45%;
    }
    #rw1 {
        justify-items: center;
    }
    #calc #inputwrap { 
        width: 80%;
    }
    #btnrow {
        width: 80%;
    }
}
@media screen and (max-width: 700px) {
    .Calculator {
        width: fit-content;
    }
    #calchead {
        padding: 0px 4%;
    }
    #calcwrap {
        padding: 0px;
        margin: 0px;
    }
    #calchead h2 {
        font-size: 30px;
        line-height: 30px;
        padding-bottom: 10px;
    }
    #rwstr {
        display: grid;
        justify-content: center;
    }
    #calc {
        margin: 0px;
        width: -webkit-fill-available;
    }
    #rwstr form {
        display: grid;
    }
    #rwstr .col-6 {
        width: 100%;
    }
    #calc #inputwrap {
        width: 90%;
        padding: 0px 0px;
        justify-self: center;
    }
    #btnrow {
        width: 100%;
        place-self: center;
    }
    #btnrow button {
        margin: 0px;
    }
}
