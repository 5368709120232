#footer {
    display: flex;
    background-color: #FFFFFF;
    padding: 25px 10%;
    justify-content: space-between;
    text-align: left;
    width: 100%;
}
/* #footer .col-4 .col-2 {
    padding: 2% 5%;
} */
#footer .col-4 {
    width: 33.33%;
}
#footer .col-4, #footer .col-2 {
    margin-right: 5%;
}
#footer .col-4 p {
    text-align: left;
    font-size: 20px;
    font-weight: 450;
    font-family: Plus Jakarta Sans;
}
#cl2sp {
    display: contents;
}
#footer .col-2 {
    font-family: Roboto;
    color: #000000;
    width: 17%;
}
#footer h3 {
    font-weight: 700;
    font-size: 20px;
}
#footer a {
    text-decoration: none;
    color: #000000;
    padding: 15px 0px;
    text-align: left;
}
#footer i {
    color: #000000;
    padding: 10px;
    padding-left: 0px;
    padding-right: 20px;
    font-size: 25px;
}
#footer a {
    text-decoration: none;
}
#footlast {
    border-top: 1px solid #5F5F7E;
    font-family: Roboto;
    font-weight: 700;
    font-size: 15px;
    color: #000000;
    text-align: center;
    padding: 10px 0px;
    background-color: #FFFFFF;
    width: inherit;
    place-content: center;
    width: 100%;
}

@media screen and (min-width: 1025px)and (max-width: 1450px) {
    #footer {
        padding: 15px 5%;
    }
    #fcol {
        margin-right: 5%;
    }
    #ftlascol {
        margin-right: 0px;
    }
    #fllw {
        font-size: 25px;
    }
    #footer .col-4 #abt {
        font-size: 18px;
    }
    #footer .col-4, #footer .col-2 {
        margin-right: 5%;
    }
}
@media screen and (min-width: 768px)and (max-width: 1024px) {
    #footer {
        padding: 10px 5%;
    }
    #footer i {
        font-size: 20px;
    }
    #footer .col-4, #footer .col-2 {
        margin-right: 10%;
    }
    #fllw {
        font-size: 25px;
    }
}
@media screen and (min-width: 768px)and (max-width: 900px) {
    #footer .col-4 #abt {
        font-size: 15px;
        width: 240px;
    }
}
@media screen and (min-width:901px)and (max-width: 1024px) {
    #footer .col-4 #abt {
        font-size: 18px;
    }
}
@media screen and (max-width: 770px) {
    #footer {
        display: grid;
        width: -webkit-fill-available;
    }
    #footer .col-4 {
        width: -webkit-fill-available;
    }
    #cl2sp {
        display: flex;
        width: -webkit-fill-available;
    }
    #footer #midcol {
        width: 50%;
        inline-size: unset;
        padding-right: 25%;
    }
    #footer .col-4, #footer #cl2sp {
        margin-right: 0px;
        margin-bottom: 7%;
    }
    #ftlascol {
        margin-bottom: 0px;
    }
    #ftlascol span {
        inline-size: auto;
        justify-content: space-between;
    }
    #ftlascol span i {
        text-align: center;
        padding: 1%;
    }
    #footlast {
        width: -webkit-fill-available;
        padding: 0px 2%;
    }
}